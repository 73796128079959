<template>
  <div>
    <a-modal
      v-model="visibleModalManageKd"
      class="mod"
      :title="status.isEdit ? 'Form Edit Basic Competence' : 'Form Create Basic Competence'"
      @cancel="toggleModalManageKd"
      centered
    >
      <form-kd
        :currentKdEdit="currentKdEdit"
        :allKd="kdDataTable"
        :loadingValidate="formValidate.loading"
        :data="newKd"
        @handle-change="handleChange"
        @validationForm="validationForm"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageKd">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageKd"
          @click="formValidate.loading = true"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-md-flex align-items center">
      <a-select
        @change="changeMapel()"
        :style="{
          display: isMobile ? 'block' : null
        }"
        :class="!isMobile ? 'mr-3' : 'mb-3'"
        style="min-width: 150px"
        v-model="idMapel"
        size="large"
      >
        <a-select-option
          v-for="subject in subjects"
          :value="subject.id"
          :key="subject.id"
          >{{ subject.nama }}</a-select-option
        ></a-select
      >
      <a-select
        style="min-width: 150px"
        @change="fetchDataKd()"
        :style="{
          display: isMobile ? 'block' : null
        }"
        :class="isMobile ? 'mb-3' : null"
        v-model="idLevel"
        size="large"
      >
        <a-select-option
          v-for="level in levels"
          :value="level.id"
          :key="level.id"
          >{{ level.nama }}</a-select-option
        ></a-select
      >
      <div class="ml-auto">
        <a-button
          :block="isMobile"
          @click.prevent="toggleModalManageKd('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Basic Competence</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        class="hasan-table-overflow"
        :columns="kdColumns"
        :data-source="kdDataTable"
        :pagination="kdPagination"
        :loading="kdLoadingTable"
        bordered
      >
        <div slot="nullStr" slot-scope="text">{{ text || "-" }}</div>
        <div slot="action" class="d-flex flex-column" slot-scope="value, record">
          <a-button
            class="mb-3 text-warning border border-warning"
            size="large"
            @click.prevent="toggleModalManageKd('edit', record)"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            class="text-danger border border-danger"
            size="large"
            @click.prevent="handleDeleteKd(record)"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
        <a-table
          :data-source="value.sesis"
          :pagination="false"
          :columns="sessionColumns"
          slot="expandedRowRender"
          slot-scope="value"
        >
          <div slot="date" slot-scope="value, record">
            <router-link
              :to="{
                name: 'Detail Schedule Teacher',
                params: { id: record.id },
              }"
              >{{ record.tanggal_sesi }}</router-link
            >
          </div>
          <span slot="isLive" slot-scope="text, record">
            <a-icon
              type="video-camera"
              v-if="record.zoom_setting"
              style="font-size: 20px"
              theme="twoTone"
              two-tone-color="#eb2f96"
            />
            <a-icon
              type="close-circle"
              v-else
              style="font-size: 20px"
              theme="twoTone"
              two-tone-color="#FF0000"
            />
          </span>
          <span slot="assignmentType" slot-scope="text">
            <a-icon
              type="paper-clip"
              v-if="text == 'File Upload'"
              style="font-size: 20px; color: #1b55e3"
            />
            <a-icon
              type="align-left"
              v-else-if="text == 'Essay'"
              style="font-size: 20px; color: #1b55e3"
            />
            <a-icon
              type="unordered-list"
              v-else-if="text == 'Multiple Choice'"
              style="font-size: 20px; color: #1b55e3"
            />
            <a-icon
              type="close-circle"
              v-else
              style="font-size: 20px"
              theme="twoTone"
              two-tone-color="#FF0000"
            />
          </span>
        </a-table>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
// import moment from 'moment'
const formKd = () => import('@/views/Teacher/Planning/New/Kd/Form')

const kdColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: 90,
  },
  {
    title: 'Basic Competence',
    width: 700,
    children: [
      {
        title: 'Knowledge',
        dataIndex: 'nama_pengetahuan',
        key: 'nama_pengetahuan',
        align: 'justify',
        scopedSlots: { customRender: 'nullStr' },
        width: 350,
      },
      {
        title: 'Skill',
        dataIndex: 'nama_keterampilan',
        key: 'nama_keterampilan',
        align: 'justify',
        scopedSlots: { customRender: 'nullStr' },
        width: 350,
      },
    ],
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]

const sessionColumns = [
  {
    title: 'Date',
    dataIndex: 'tanggal_sesi',
    key: 'tanggal_sesi',
    width: 200,
    scopedSlots: { customRender: 'date' },
    align: 'center',
  },
  {
    title: 'Class',
    dataIndex: 'kelas',
    key: 'kelas',
    align: 'center',
    width: 100,
  },
  {
    title: 'Live',
    dataIndex: 'zoom_setting',
    key: 'zoom_setting',
    align: 'center',
    scopedSlots: { customRender: 'isLive' },
  },
  {
    title: 'Assign',
    dataIndex: 'tipe_assignment',
    key: 'tipe_assignment',
    align: 'center',
    scopedSlots: { customRender: 'assignmentType' },
  },
]
export default {
  name: 'kd',
  components: {
    formKd,
  },
  props: {
    subjects: {
      type: Array,
      required: true,
    },
    levels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      kdColumns,
      sessionColumns,
      visibleModalManageKd: false,
      visibleModalAddSession: false,
      loadingActionModalManageKd: false,
      loadingDeletekd: false,
      kdLoadingTable: false,
      kdDataTable: [],
      kdPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newKd: {
        nama_pengetahuan: null,
        nama_keterampilan: null,
        no: null,
        semester: 'Ganjil',
        is_mid_report: true,
      },
      currentKdEdit: {},
      idMapel: null,
      idLevel: null,
      listSubjects: [],
      listLevels: [],
      formValidate: {
        loading: false,
        isValidated: false,
      },
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      // console.log(value, column, this.newKd)
      this.newKd[column] = value
    },
    toggleModalManageKd(status, data) {
      this.visibleModalManageKd = !this.visibleModalManageKd
      if (!this.visibleModalManageKd) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newKd = {
            nama_pengetahuan: null,
            nama_keterampilan: null,
            no: null,
            semester: 'Ganjil',
            is_mid_report: true,
          }
          this.currentKdEdit = {}
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.currentKdEdit = data
          const { no, nama_pengetahuan: knowledge, nama_keterampilan: skill, semester, is_mid_report: isMidReport } = data
          this.newKd = {
            nama_pengetahuan: knowledge,
            nama_keterampilan: skill,
            is_mid_report: isMidReport,
            no,
            semester,
          }
        }
      }
    },
    handleOkModalManageKd() {
      if (!this.formValidate.isValidated) {
        return this.$notification.error({
          message: 'Validation Failed',
          description: 'Please check your fields.',
        })
      }
      const content = `${this.status.isEdit ? 'Are you sure want to edit this basic competence?' : 'Are you sure want to create new basic competence'}`
      this.$confirm({
        title: 'Warning',
        content: (
          <div>{content}</div>
        ),
        onOk: async () => {
          try {
            this.loadingActionModalManageKd = true
            const payload = this.status.isEdit ? {
              id: this.currentKdEdit.id,
              dataKd: this.newKd,
            } : {
              idMapel: this.idMapel,
              idLevel: this.idLevel,
              dataKd: this.newKd,
            }
            console.log(payload, 'tesss')
            await this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_KD`, payload)
            this.loadingActionModalManageKd = false
            this.toggleModalManageKd()
            await this.fetchDataKd()
            const description = this.status.isEdit ? 'Basic competence has been updated' : 'Basic competence has been created'
            this.$notification.success({
              message: 'Success',
              description,
            })
          } catch (err) {
            const description = this.status.isEdit ? 'Basic competence has not been updated' : 'Basic competence has not been created'
            this.$notification.error({
              message: 'Failed',
              description,
            })
          }
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
        cancelText: 'Cancel',
      })
    },
    handleDeleteKd(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this basic competence?</div>
        ),
        onOk: async () => {
          try {
            this.loadingDeleteKd = true
            await this.$store.dispatch('master/DELETE_KD', {
              id: data.id,
            })
            await this.fetchDataKd()
            this.loadingDeleteKd = false
            this.$notification.success({
              message: 'Success',
              description:
                'Basic competence has been deleted',
            })
          } catch (err) {
            this.loadingDeleteKd = false
            console.log(err)
            this.$notification.error({
              message: 'Failed',
              description: 'Basic competence has not been deleted',
            })
          }
        },
        onCancel: () => {
          this.loadingDeleteKd = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    async fetchDataKd() {
      try {
        this.kdLoadingTable = true
        const { data } = await this.$store.dispatch('master/FETCH_KD_MASTER', { idMapel: this.idMapel, idLevel: this.idLevel })
        this.kdLoadingTable = false
        // const pagination = { ...this.pagination }
        // pagination.total = data.total
        // this.pagination = pagination
        // console.log(res)
        this.kdDataTable = data.map(el => {
          let newSesi = el.sesis.sort((a, b) => {
            const tanggal1 = moment(a.tanggal_sesi, 'YYYY-MM-DD')
            const tanggal2 = moment(b.tanggal_sesi, 'YYYY-MM-DD')
            return tanggal1 - tanggal2
          })
          newSesi = newSesi.map(sesi => {
            const kelas = sesi.slot.kela.level.nama + '-' + sesi.slot.kela.simbol
            const tanggalSesi = moment(sesi.tanggal_sesi, 'YYYY-MM-DD').format('DD MMMM YYYY')
            return {
              ...sesi,
              kelas,
              tanggal_sesi: tanggalSesi,
              key: sesi.id,
            }
          })
          return {
            ...el,
            sesis: newSesi,
            key: el.id,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchSubjectsAndLevelsByTeacher() {
      try {
        const { data } = await this.$store.dispatch('subject/FETCH_TEACHER_SUBJECTS')

        this.listSubjects = data.subjects
        this.listLevels = data.levels
        this.idMapel = this.listSubjects[0]?.id
        this.idLevel = this.listLevels[0]?.id
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    validationForm(isValidated) {
      this.formValidate = {
        loading: false,
        isValidated,
      }
      this.handleOkModalManageKd()
    },
    async changeMapel() {
      try {
        this.$emit('changeMapel', this.idMapel)
        this.idLevel = this.levels[0]?.id
        await this.fetchDataKd()
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  async created() {
    try {
      this.idMapel = this.subjects[0]?.id
      await this.changeMapel()
    } catch (err) {
      console.log(err)
      this.$notification.error({
        message: 'ERROR_SERVER',
      })
    }
    // this.kdDataTable = [
    //   {
    //     key: 1,
    //     no: '3',
    //     nama_pengetahuan: 'Menjelaskan konsep campuran dan zat tunggal (unsur dan senyawa), sifat fisika dan kimia, perubahan fisika dan kimia dalam kehidupan sehari-hari',
    //     nama_keterampilan: 'Memeragakan konsep campuran dan zat tunggal (unsur dan senyawa), sifat fisika dan kimia, perubahan fisika dan kimia dalam kehidupan sehari-hari',
    //     session: [
    //       {
    //         key: 1,
    //         kelas: 'VII - 2',
    //         date: moment().format('DD MMM YYYY HH:mm'),
    //         isLive: true,
    //         assignmentType: 'Essay',
    //       },
    //     ],
    //   },
    // ]
  },
  computed: {
    // isValidateForm() {
    //   // if (this.newKd.nama) {
    //   //   return false
    //   // }
    //   return true
    // },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style>
</style>
